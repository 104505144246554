<template>
<NavBar @editStart="StartEdit()"/>

<h1 style="text-align: center;">תקנון האתר</h1>

<p class="termsParagrafh">{{ this.Terms }}</p>

<div class="empty">

</div>


<Footer/>

</template>

<script>
import NavBar from '@/components/NavBarcopy.vue'
import Footer from '@/components/Footer.vue'
// import EditTool from '@/components/EditTool.vue'
// import emailjs from '@emailjs/browser';


import axios from 'axios'


export default {
  name: 'Terms',
  components: {
    NavBar,
    Footer,
    // EditTool
},
  data(){
      return{
        Terms:
        `
        גולש יקר, השימוש באתרsnt-law.co.il  (להלן: “האתר”) ובתכנים המוצגים בו כפוף לתנאי
השימוש המפורטים להלן:
בטרם השימוש באתר, עליך לקרוא תנאים אלה בעיון. השימוש באתר זה מעיד כי הנך
מסכים ומקבל את התקנון להלן.
האמור בתקנון זה מתייחס באופן שווה לבני שני המינים והשימוש בלשון זכר נעשה מטעמי
נוחות בלבד.
1. האתר מנוהל ע”י עו”ד שרון נתח תמרי.
2.מפעילי האתר ו/או כל אדם מטעמם לא יישאו בכל אחריות ביחס לתכני האתר בכל הנוגע
למהימנותם, דיוקם, אמינותם, והשפעתם על מחשבים של הגולשים באתר. כן לא יישאו
מפעילי האתר בכל אחריות לנזק, אי נוחות, אבדן, עוגמת נפש ובתוצאות ישירות או עקיפות
שייגרמו לגולשים.
3. השימוש באתר ו/או בתכנים ו/או מידע שבו הינו באחריותו הבלעדית של הגולש ו/או
המשתמש ולמפעילי האתר לא תהיה כל אחריות מכל סוג שהוא כלפי משתמש כזה או אחר
בגין כך.
4. השירותים הניתנים באתר אינם כרוכים בתשלום וניתנים לרווחת הגולשים וכל שימוש
הנעשה באתר, על כל שירותיו, ניתן כמות שהוא (AS IS) והנו באחריות הבלעדית של
הגולש.
5. כל המידע המצוי באתר משמש למידע כללי בלבד. אנו עושים כל שביכולתנו על מנת
לספק מידע מועיל ועדכני, אך איננו מתחייבים שכל המידע המופיע באתר מדויק, שלם
ומעודכן.
6. כל האמור באתר אינו מהווה ואינו יכול להוות תחליף לייעוץ משפטי, ואין באמור
באתר משום הצעה או קיבול לקבל או ליתן ייצוג או ייעוץ משפטי. בנוסף, המידע המצוי
באתר וכל קבלה של
מידע אינם מקיימים יחסי עורך דין – לקוח.

7. מפעילי האתר לא יישאו באחריות כלשהי, עקיפה או ישירה, לכל נזק שייגרם כתוצאה
מהשימוש באתר ו/או בכל מידע המפורסם בו.
8. אין המידע באתר, לרבות קישורי האינטרנט המפורסמים בו ולרבות תכנים ו/או מידע אשר
נמצא בבעלות צד שלישי, מהווים תחליף למקורות ו/או לייעוץ משפטי על-ידי משפטן מוסמך.
9. האתר ומפעיליו אינם אחראים על טעויות, השמטות, אי-דיוקים או מידע מוטעה המצוי
בבעלות ו/או מנוהל ו/או מופעל על ידי צד שלישי, בין אם פורסם באתר באופן ישיר ובין אם
פורסם בדרך של הפנייה ו/או קישור אינטרנט ו/או בכל דרך אחרת, והמשתמש מקבל על
עצמו, את מלוא הסיכון והאחריות בגין שימושו בתכני האתר.
10. במידה ותוכן באתר פוגע בזכויות קנייניות ו/או זכויות יוצרים ו/או כל זכות אחרת של אדם
או גוף, לרבות מידע הפוגע בפרטיותו של אדם, ובכלל זה של המשתמש, יש לפנות אל נציגי
האתר. כל פנייה תיבדק לגופה ולבעלי ו/או למפעילי האתר ו/או למי מטעמם מסור שיקול
הדעת הבלעדי באשר לטיפול בפנייה זו.
11. המשתמש מחויב לאמת ולבדוק כל נתון, מידע, תוכן או חישוב שנתקבל בעת השימוש
באתר, ואינו רשאי להסתמך עליו או להעבירו לצד ג’.

12. המידע הנ”ל אינו מהווה ייעוץ משפטי או תחליף לו ואינו מהווה המלצה לנקיטת הליכים
או להימנעות מהם. ייתכן ובמידע חלו השמטות ו/או טעויות. כל המסתמך על המידע בכל
דרך שהיא עושה זאת על אחריותו בלבד ומסיר ממפעילי האתר כל אחריות. המידע הינו
חסוי ומיועד לנמען בלבד. אין לעשות במידע שימוש כלשהו ו/או להעבירו לצדדים שלישיים.
13. המשתמש מצהיר כי הינו מוותר בזה על כל טענה ו/או תביעה ו/או דרישה כנגד מפעילי
האתר ו/או עובדיהם ו/או מי מטעמם בכל הנוגע לשימוש ע”י אותו גולש בתוכן ו/או במידע
הקיים ו/או שיהיה באתר בעתיד.
14. ידוע למשתמש כי התכנים ו/או המידע שבאתר הינם כלליים וחלקיים בלבד ויתכן כי נפלו
בהם טעויות ו/או שגיאות כאלה או אחרות ונכונות. כן ידוע למגולש כי המידע ו/או התכנים
עלולים ויכולים להשתנות מעת לעת עפ”י הצורך.
15. הגולש מצהיר כי ידוע לו שבכל מקרה, יש לבחון כל מקרה לנסיבותיו המיוחדות וכי עליו
לפנות לעו”ד המתאים למקרה לצורך התייעצות מפורטת ומקצועית יותר.
16. בנוסף, מובהר כי אין בציון ו/או אזכור פרטי עו”ד ו/או נותן שירותים כזה או אחר ו/או
קישור אליהם באתר כדי להוות המלצה כזאת או אחרת לגולש לנקוט ו/או להמנע מפעולה
כזו או אחרת.
17. בעלי האתר ו/או מפעיליו ו/או כותבי התכנים המתפרסמים באתר, אינם אחראים בשום
מקרה לכל נזק ו/או בעיות אשר יגרמו עקב השימוש באתר ו/או בכל חלק ממנו ו/או בכל מידע
ו/או תוכן המפורסם בהם.
18. למפעילי האתר הזכות הבלעדית לערוך שינויים לרבות הוספת או הסרת תכנים, מידע,
תמונות מהאתר לפי שיקול דעתם הבלעדי והמוחלט.
19. כל זכויות היוצרים ו/או הקניין הרוחני באתר- לרבות בשם http://www.snt-law.co.il,
בעיצוב האתר, בתכנים המתפרסמים בו ובכל תוכנה, קוד מחשב, יישום, קובץ גרפי, טקסט
וכל חומר אחר הכלולים בהם – הנם של מפעילי האתר או של כותבי התכנים בלבד, לפי
העניין.
20. מובהר בזאת כי אין לשנות, לשכתב, לתקן, למחוק, להוסיף, להעתיק, לצלם, לשכפל,
לפרסם, להציג בפומבי, להפיץ, למסור לצד שלישי או לעשות כל שימוש אחר, מסחרי או לא
מסחרי אחר, בכל חלק מן הנ”ל בלא קבלת הסכמתם המפורשת של בעלי הזכויות בתכנים
ו/או בקבצים ו/או ביישומים ו/או בטקסטים, לפי העניין בכתב ומראש.
21. מובהר בזאת כי בעלי האתר ו/או מפעיליו ו/או מי מטעמם אינם מחוייבים, בשום מקרה
לענות ו/או להתייחס לשאלות ו/או לפניות שנשלחו לאתר. לעיתים לא ניתן לענות על שאלות
הגולשים ללא פירוט של עובדות המקרה או במקרה בו לא ניתן לתת ייעוץ באמצעות
האינטרנט ללא פגישת ייעוץ אישית עם עו”ד המתמחה בתחום.
22. האתר מאפשר פנייה לצורך יעוץ אל המפרסמים באתר. האחריות בגין השימוש
באפשרות פניה לעורך דין לרבות כל נזק ישיר, עקיף ו/או תוצאתי שייגרמו בגין שימוש שכזה,

הינה על אחריות המשתמש, שואל השאלה, או עורך הדין, בלבד ובשום מקרה לא תהיה על
בעלי האתר ו/או מפעיליו ו/או מי מטעמם כל אחריות לכך.
23. בעלי האתר ו/או מפעיליו יעשו כל מאמץ סביר כדי להגן על פרטי המשמש ופרטיותו
בהתאם למדיניות הפרטיות המופיעה באתר. ואולם, חשוב להבהיר כי לא ניתן להבטיח הגנה
מלאה על פרטים אלו. בנסיבות אלה, המשתתף פוטר בזה את בעלי האתר ו/או מפעיליו ו/או
מי מטעמם מכל אחריות לכל פגיעה או נזק ישיר או עקיף, כספי או אחר, שיגרם למשתמש
כתוצאה מדליפת פרטים אלה.
24. דיני מדינת ישראל יחולו על תקנון ואתר זה ועל כל הנובע ממנו או הכרוך בו והוא יפורש
על פיהם. כל סכסוך או שאלה משפטית בקשר לתקנון זה יתברר בערכאה השיפוטית
המוסמכת במחוז הצפון.
 
שימוש מהנה ומשכיל snt-law.co.il

        `
      }
      
  },
  created(){
  },
  mounted(){
  },
  methods: {
    
  } 
}
</script>

<style scoped>

*{
  margin: 0;
  padding: 0;
}

/* -------------------- empty ------------------- */
.empty{
  height: 100px;
}

.backgroundBlog{
  border: 1px solid none;
  background-image: url('@/assets/photo/BlogBackground.jpg');
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.TitleDiv{
  border: 1px solid none;
  width: 100%;
  height: auto;
  text-align: center;
  background: #D4AF37;
background: linear-gradient(to bottom, #D4AF37 0%, #C5A028 100%);
background: -webkit-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
background: -moz-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
background: -o-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
background: -ms-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
border: 1px solid #B69119;
box-shadow: inset 0 1px 0 #E3BE46;
-webkit-box-shadow: inset 0 1px 0 #E3BE46;
-moz-box-shadow: inset 0 1px 0 #E3BE46;
  font-size: 2.2vw;
  padding: 15px;
}

.TitleDiv h1{
  text-align: center;
  color: #053c67;
}

.VideoContainer{
  border: 1px solid none;
  display: flex;
  position: relative;
  right: 50%;
  transform: translate(50%);
  width: 90%;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

p.termsParagrafh{
  white-space: pre-line; 
  text-align: right;
  margin-top: 50px;
  border: 1px solid none;
  position: relative;
  width: fit-content;
  padding: 30px;
  right: 50%;
  font-size: 1.2vw;
  transform: translate(50%);
}

@media (max-width: 1200px) {
    p.termsParagrafh{
    font-size: 2vw;
  }
}

@media (max-width: 700px) {

  p.termsParagrafh{
    padding: 25px !important;
    font-size: 2.5vw;
  }
}


</style>




